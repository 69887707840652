import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Components
import { BuilderPage, Footer, Header } from './components';

// Pages
import { Test } from './pages';


function App() {
  return (
    <Router>
      <div className="layout-wrapper">
        <div className="content">
          <Header />
          {/* These routes are direct imports from Builder.io and need to be created there first */}
          <Routes>
            <Route path="/" element={<BuilderPage modelName="page" urlPath="/" />} />
            {/* this needs the whitespace after the word rail as there is a bug that adds an after to it */}
            <Route path="/rail-courses" element={<BuilderPage modelName="page" urlPath="/rail-courses" />} /> 
            <Route path="/barbering" element={<BuilderPage modelName="page" urlPath="/barbering" />} />
            <Route path="/FSK" element={<BuilderPage modelName="page" urlPath="/FSK" />} />
            <Route path="/info-tech" element={<BuilderPage modelName="page" urlPath="/info-tech" />} />
            <Route path="/business" element={<BuilderPage modelName="page" urlPath="/business" />} />
            <Route path="/short-courses" element={<BuilderPage modelName="page" urlPath="/courses" />} />
            <Route path="/railCore" element={<BuilderPage modelName="page" urlPath="/railCore" />} />
            Routes below are for testing only not production
            {/* <Route path='/rail' element={<Test />} /> */}
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
