import React from 'react';
// Import your logo image
import defaultLogo from '../images/ph_logo.png';

interface LogoProps {
  logo?: string;
  size?: number;
  type?: 'small' | 'medium' | 'large';
}

const Logo: React.FC<LogoProps> = ({ logo = defaultLogo, size = 200, type }) => {
  // Set the size based on the type prop
  switch (type) {
    case 'small':
      size = 80;
      break;
    case 'medium':
      size = 200;
      break;
    case 'large':
      size = 300;
      break;
    default:
      size = size;
      break;
  }

  return (
    <img
      className="logo"
      src={logo}
      alt="logo"
      width={size}
      height={size}
    />
  );
};

export default Logo;
