import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

interface ActiveLinkProps extends LinkProps {
  children: React.ReactNode;
  className?: string;
}

const ActiveLink: React.FC<ActiveLinkProps> = ({ children, to, className, ...props }) => {
  const location = useLocation();

  // Check if the current pathname matches the 'to' prop
  const isActive = location.pathname === to;
  const activeClass = isActive ? 'active' : '';

  return (
    <Link to={to} className={`${className || ''} ${activeClass}`} {...props}>
      {children}
    </Link>
  );
};

export default ActiveLink;
