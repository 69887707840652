import React, { useEffect, useState } from 'react';
import { BuilderComponent, builder } from '@builder.io/react';

// Initialize Builder.io with the API key
builder.init(process.env.REACT_APP_BUILDER_PUBLIC_API_KEY!);

interface BuilderPageProps {
  modelName: string;
  urlPath?: string;
}

const BuilderPage: React.FC<BuilderPageProps> = ({ modelName, urlPath = '/' }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    builder.get(modelName, { userAttributes: { urlPath } })
      .promise()
      .then((data) => {
        setContent(data);
      })
      .catch((err) => {
        console.error(`Error fetching content for ${modelName}:`, err);
      });
  }, [modelName, urlPath]);

  return (
    <div>
      {content ? (
        <BuilderComponent model={modelName} content={content} />
      ) : (
        <div>Loading Builder.io content...</div>
      )}
    </div>
  );
};

export default BuilderPage;
