import React, { ReactNode, useState, useRef, useEffect } from 'react';
import './styles/accordion.scss';

interface AccordionProps {
  items: { title: string; content: string | ReactNode }[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [heights, setHeights] = useState<number[]>([]);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // Calculate heights of all content divs
    const newHeights = contentRefs.current.map((ref) => (ref?.scrollHeight) || 0);
    setHeights(newHeights);
  }, [items]);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index}>
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(index)}
          >
            <div>{item.title}</div>
            <div className='add'>+</div>
          </div>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className={`accordion-content ${index === activeIndex ? 'active' : ''}`}
            style={{
              height:
                index === activeIndex ? `${heights[index]}px` : '0',
            }}
          >
            <div className="accordion-content-inner">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
