// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-container .list {
  padding-bottom: 10px;
  list-style-type: none;
}
.list-container h5 {
  padding-bottom: 5px;
  color: #393939;
  font-size: 22px;
}
.list-container .list-item {
  padding: 2px 0px 2px 5px;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/list.scss"],"names":[],"mappings":"AAEE;EACE,oBAAA;EACA,qBAAA;AADJ;AAGE;EACE,mBAAA;EACA,cAAA;EACA,eAAA;AADJ;AAGE;EACE,wBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADJ","sourcesContent":[".list-container {\n\n  & .list {\n    padding-bottom: 10px;\n    list-style-type: none;\n  }\n  & h5 {\n    padding-bottom: 5px;\n    color: #393939;\n    font-size: 22px;\n  }\n  & .list-item {\n    padding: 2px 0px 2px 5px;\n    font-size: 18px;\n    font-weight: 500;\n    color: #393939;\n\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
