import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './styles/footer.scss';

interface FooterProps {
  withSocials?: boolean;
  extraText?: string;
}

const clientName = 'Training Professionals';

const Footer: React.FC<FooterProps> = ({ withSocials = true, extraText = ''}) => {
  return (
    <footer className='footer-container'>
      {withSocials &&
        <div className="social-icons">
          <a href="https://twitter.com" id='twitter' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://facebook.com" id='facebook' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon  icon={faFacebook} />
          </a>
          <a href="https://instagram.com" id='instagram' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon  icon={faInstagram} />
          </a>
          <a href="https://linkedin.com" id='linkedin' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      }
      {extraText !== '' && <p>Something specific to the client</p>}
      <p>{clientName} &copy; 2024</p>
    </footer>
  );
};

export default Footer;