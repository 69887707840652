// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-container {
  margin-top: 6rem;
  width: 100%;
}

.accordion {
  width: 80%;
  margin: 0 auto;
}
.accordion .accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #393939;
  border-bottom: 1px solid #ddd;
  color: #fff;
  font-size: 22px;
  margin: 0;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.accordion .accordion-header:hover {
  color: #955cce;
}
.accordion .accordion-header .add {
  font-size: 30px;
  font-weight: 600;
}
.accordion .accordion-header div {
  padding: 0;
  margin: 0;
}
.accordion .accordion-content {
  overflow: hidden;
  width: 99%;
  margin: 0 auto;
  height: 0;
  transition: height 0.2s ease-out;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1), -3px 0 5px rgba(0, 0, 0, 0.1);
}
.accordion .accordion-content.active {
  transition: height 0.2s ease-in;
}
.accordion .accordion-content-inner {
  padding: 0px 0px 0px 15px;
  color: #393939;
  font-size: 22px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/accordion.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,cAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,aAAA;EACA,yBAAA;EACA,6BAAA;EACA,WAAA;EACA,eAAA;EACA,SAAA;EACA,4BAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AADI;EACE,cAAA;AAGN;AADI;EACE,eAAA;EACA,gBAAA;AAGN;AADI;EACE,UAAA;EACA,SAAA;AAGN;AACE;EACE,gBAAA;EACA,UAAA;EACA,cAAA;EACA,SAAA;EACA,gCAAA;EACA,uEAAA;AACJ;AAGE;EACE,+BAAA;AADJ;AAIE;EACE,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAFJ","sourcesContent":[".accordion-container {\n  margin-top: 6rem;\n  width: 100%;\n}\n\n.accordion {\n  width: 80%;\n  margin: 0 auto;\n  .accordion-header {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px;\n    background-color: #393939;\n    border-bottom: 1px solid #ddd;\n    color: #fff;\n    font-size: 22px;\n    margin: 0;\n    padding: 10px 20px 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n    &:hover {\n      color: #955cce;\n    } \n    .add {\n      font-size: 30px;\n      font-weight: 600;\n    }\n    & div {\n      padding: 0;\n      margin: 0;\n    }\n  }\n  \n  .accordion-content {\n    overflow: hidden;\n    width: 99%;\n    margin: 0 auto;\n    height: 0;\n    transition: height 0.2s ease-out;\n    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.1), -3px 0 5px rgba(0, 0, 0, 0.1);\n\n  }\n  \n  .accordion-content.active {\n    transition: height 0.2s ease-in;\n  }\n  \n  .accordion-content-inner {\n    padding: 0px 0px 0px 15px;\n    color: #393939;\n    font-size: 22px;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
