import React from 'react';

import './styles/list.scss';

interface ListProps {
  heading: string;
  items: string[];
}

const List: React.FC<ListProps> = ({ heading, items}) => {
  return (
    <div className='list-container'>
      <h5>{heading}</h5>
      <ul className='list'>
        {items.map((item, index) => (
          <li className='list-item' key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default List;