import React, { useState } from 'react';

import './styles/enquire.scss';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
}

const Enquire: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    //  TO DO: Connect to API
  };

  const isFieldFilled = (fieldValue: string): boolean => {
    return fieldValue.trim() !== ''
  };
  

  // Helper function to check if all fields are filled
  const isFormValid = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.phone.trim() !== '' &&
      formData.message.trim() !== ''
    );
  };

  return (
    <div className='form-container'>
      <form className='enquire-form' onSubmit={handleSubmit}>
        <div className='form-row'>
          <label htmlFor="firstName">
            First Name
            <span className='star' style={{ visibility: !isFieldFilled(formData.firstName) ? 'visible' : 'hidden'}}>*</span>
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>

        <div className='form-row'>
          <label htmlFor="lastName">
            Last Name
            <span className='star' style={{ visibility: !isFieldFilled(formData.lastName) ? 'visible' : 'hidden'}}>*</span>
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className='form-row'>
          <label htmlFor="email">
            Email
            <span className='star' style={{ visibility: !isFieldFilled(formData.email) ? 'visible' : 'hidden'}}>*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
      
         <div className='form-row'>
          <label htmlFor="phone">
            Phone
            <span className='star' style={{ visibility: !isFieldFilled(formData.phone) ? 'visible' : 'hidden'}}>*</span>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
      
        <div className='form-row'>
          <label htmlFor="message">
            Message
            <span className='star' style={{ visibility: !isFieldFilled(formData.message) ? 'visible' : 'hidden'}}>*</span>
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" disabled={!isFormValid()}>Enquire Now</button>
        <div className='helper-text' style={{ visibility: !isFormValid() ? 'visible' : 'hidden'}}>Please fill all fields marked with <span className='star'>*</span></div>
      </form>
    </div>
  );
};

export default Enquire;
